// import smoothscroll from 'smoothscroll-polyfill';
// smoothscroll.polyfill();

class App {
    constructor() {
        this.datas = [];
        this.slide_time = 3000;
        this.transition_time = 1000;
    }
    // page_scroll(mySelecor) {
    //     console.log(mySelecor);
    //     const targetElement = document.querySelector(mySelecor);
    //     if (!targetElement) {
    //         return;
    //     }

    //     // 画面上部から要素までの距離
    //     const rectTop = targetElement.getBoundingClientRect().top;
    //     // 現在のスクロール距離
    //     const offsetTop = window.pageYOffset;
    //     // スクロール位置に持たせるバッファ
    //     const buffer = 0;
    //     const top = rectTop + offsetTop - buffer;

    //     window.scrollTo({
    //         top,
    //         behavior: 'smooth',
    //     });
    // }

    scrollObserver() {
        const $header = $('.header');
        $(window).on('load scroll resize', function () {
            if ($(window).scrollTop() > 20) {
                $header.addClass('on');
            } else {
                $header.removeClass('on');
            }
        });
    }

    gnav() {
        const trigger = document.querySelector('.menu-trigger');
        const header_nav = document.querySelector('.header-nav');
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            trigger.classList.toggle('active');
            header_nav.classList.toggle('active');
        }, false);
    }

    detailSlide() {
        const $slidesMain = $('.slides-main');
        if ($slidesMain.length > 0) {
            let thumbs = $slidesMain.html();
            $slidesMain.after('<div class="slides-sub"><div class="slides-sub-inner"></div></div>');
            const $slidesSub = $('.slides-sub-inner');
            $slidesSub.html(thumbs);
            $slidesMain.slick({
                arrows: false,
                infinite: true,
                asNavFor: '.slides-sub-inner',
            });
            $slidesSub.slick({
                arrows: true,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: '.slides-main',
                responsive: [{
                    breakpoint: 768,  //ブレイクポイントを指定
                    settings: {
                        slidesToShow: 2,
                    },
                }],
            });
        }
    }

    input_file() {
        const formFiles = document.querySelectorAll('.input-file');
        if (formFiles.length > 0) {
            formFiles.forEach((elm) => {
                elm.onchange = function () {
                    const label = this.nextElementSibling;
                    let spans = label.querySelector('span');
                    if (spans) {
                        spans.remove();
                    }
                    const file = this.files[0].name;
                    const span = document.createElement('span');
                    span.innerText = file;
                    label.appendChild(span);
                };
            });
        }
    }

    init() {
        this.scrollObserver();
        this.detailSlide();
        // _self.input_file();
        this.gnav();
    }
}

let app = new App();

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

window.addEventListener('load', () => {

});
